export const formatNumber = (number, decimals = false) => {
  const decimalSeparator = ','
  const thousandsSeparator = '.'

  const int = Math.floor(number)
  const fraction = Math.round(number * 100) % 100

  let formattedInt = int
    .toString()
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    .join(thousandsSeparator)
    .split('')
    .reverse()
    .join('')
  if (decimals) {
    const formattedFraction = ('0' + fraction).slice(-2)

    formattedInt = formattedInt + decimalSeparator + formattedFraction
  }

  return formattedInt
}

export const formatPrice = (number, decimals = false, convertToEuro = true) => {
  if (typeof number === 'number') {
    const price = convertToEuro ? number / 100 : number
    return formatNumber(price, decimals)
  } else {
    return number
  }
}

export const formatMileage = formatNumber

export const formatCount = formatNumber

export default {
  formatPrice,
  formatMileage,
  formatCount,
  formatNumber
}
