import { crossedOutPriceLegalNotes } from '@/shared/definitions/legal'

export default {
  methods: {
    getRateBottomLabel(formattedNetRate, vatRate) {
      let subline = null

      if (vatRate !== undefined) {
        subline = this.$t('price.indications.net', {
          price: formattedNetRate
        })
      }

      return subline
    },
    /**
     *
     * @param {*} formattedNetPrice
     * @param {*} vatRate
     * @returns
     */
    getPriceSubline(formattedNetPrice, vatRate) {
      let subline = this.$t('vat.difference')

      if (vatRate !== undefined) {
        subline = this.$t('price.indications.net', {
          price: formattedNetPrice
        })
      }

      return subline
    },

    /**
     *
     * @param {*} vatRate
     * @returns
     */
    getPriceTaxInfo(vatRate) {
      let info = this.$t('vat.difference')

      if (vatRate !== undefined) {
        info = this.$t('vat.included-without-vat-value')
      }

      return info
    },

    /**
     * returns the crossed out price suffix depending on the vehicle type
     * @param {*} vehicleType
     */
    getCrossedOutPriceSuffix(vehicleType) {
      let suffix
      vehicleType = vehicleType.toLowerCase()

      if (['d', 'v'].includes(vehicleType)) {
        suffix = this.$t('price.labels.formerly-recommended-price')
      } else if (vehicleType === 'n') {
        suffix = this.$t('price.labels.recommended-price')
      } else {
        suffix = ''
      }

      return suffix
    },

    getCrossedOutPriceLegalTexts(vehicleType) {
      let found

      const mapping = crossedOutPriceLegalNotes.map(data => {
        return Object.assign({}, data, {
          text: this.$t(data.text)
        })
      })

      if (vehicleType !== undefined) {
        found = mapping.find(d =>
          d.vehicleTypes.includes(vehicleType.toLowerCase())
        )
      }

      return vehicleType !== undefined ? found : mapping
    }
  }
}
