export default {
  methods: {
    getVehicleConsumptionLegalText(rawVehicleData, shortVersion = false) {
      let {
        consumptions,
        emissions,
        hybridPlugin,
        fuel,
        range,
        equivalentAllElectricRange
      } = rawVehicleData

      let text

      const isPluginHybrid = hybridPlugin === true
      const isElectric = fuel === 'ELECTRICITY'
      const getValue = v => (v === undefined ? '-' : v)

      const templateVersion = shortVersion ? 'short' : 'default'

      if (isElectric) {
        if (
          consumptions?.power?.combined !== undefined &&
          emissions?.combined?.co2 !== undefined
        ) {
          text = this.$t(
            'vehicle.legal.efficiency.bev.templates.' + templateVersion,
            {
              consumptionPower: getValue(consumptions.power.combined),
              co2Emission: getValue(emissions.combined.co2),
              co2Class: getValue(emissions.combined.co2Class),
              range:
                range === undefined
                  ? ''
                  : ' ' +
                    this.$t(
                      'vehicle.legal.efficiency.bev.partials.electricRange',
                      {
                        range: range
                      }
                    )
            }
          )
        }
      } else if (isPluginHybrid) {
        if (
          consumptions?.weightedCombinedFuel !== undefined &&
          consumptions?.weightedCombinedPower !== undefined &&
          emissions?.combined?.co2 !== undefined
        ) {
          text = this.$t(
            'vehicle.legal.efficiency.phev.templates.' + templateVersion,
            {
              consumptionFuel: getValue(consumptions.weightedCombinedFuel),
              consumptionFuelDischarged: getValue(consumptions.fuel.combined),
              consumptionPower: getValue(consumptions.weightedCombinedPower),
              co2Emission: getValue(emissions.combined.co2),
              co2Class: getValue(emissions.combined.co2Class),
              dischargedCo2Class: getValue(emissions.discharged.co2Class),
              range:
                equivalentAllElectricRange === undefined
                  ? ''
                  : ' ' +
                    this.$t(
                      'vehicle.legal.efficiency.phev.partials.electricRange',
                      {
                        range: equivalentAllElectricRange
                      }
                    )
            }
          )
        }
      } else if (
        consumptions?.fuel?.combined !== undefined &&
        emissions?.combined?.co2 !== undefined &&
        emissions?.combined?.co2Class !== undefined &&
        fuel !== undefined
      ) {
        const consumptionUnit = ['HYDROGENIUM', 'CNG'].includes(fuel)
          ? 'KILOGRAMM'
          : 'LITER'

        // all nonelectric vehicles
        text = this.$t(
          'vehicle.legal.efficiency.ice.templates.' + templateVersion,
          {
            consumptionFuel: getValue(consumptions.fuel.combined),
            consumptionUnit: this.$t(
              'vehicle.consumptionUnit' + consumptionUnit
            ),
            co2Emission: getValue(emissions.combined.co2),
            co2Class: getValue(emissions.combined.co2Class)
          }
        )
      }

      return text
    }
  }
}
