// text values contain translation keys
export const crossedOutPriceLegalNotes = [
  {
    vehicleTypes: ['g'],
    referenceNumber: 2,
    text: 'legal.crossed-out-price.used-car'
  },
  {
    vehicleTypes: ['d', 'v'],
    referenceNumber: 3,
    text: 'legal.crossed-out-price.company-car'
  },
  {
    vehicleTypes: ['n'],
    referenceNumber: 4,
    text: 'legal.crossed-out-price.new-car'
  }
]

export default {
  crossedOutPriceLegalNotes
}
