<script>
export default {
  name: 'AmuiI18n',

  props: {
    term: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: false,
      default: 'span'
    }
  },

  render(h) {
    const slotReplacementRegexPattern = /({[\w]*})/g
    const splittedTerm = this.term.split(slotReplacementRegexPattern)

    return h(
      this.tag,
      splittedTerm.map(t => {
        let node = t
        const regex = new RegExp(slotReplacementRegexPattern)

        if (regex.test(t)) {
          const slotName = t.replace('{', '').replace('}', '')
          node = h('span', this.$slots[slotName])
        }

        return node
      })
    )
  }
}
</script>
