import { mapState, mapGetters } from 'vuex'
import {
  getTitle as getVehicleTitle,
  getTypeLetter as getVehicleTypeLetter,
  getDeliveryDateInWeeks
} from '@/../utils/vehicle'
import { resolveSettingByVehicleType } from '@/../utils/settings'
import { getNormalizedPriceData, getCrossedOutPrice } from '@/../utils/price'
import { getMonth, getYear, parseISO } from 'date-fns'

export default {
  computed: {
    ...mapState('core', ['settings']),
    ...mapGetters('core', ['getDealerByMobileSellerId'])
  },

  methods: {
    getVehicleTrackingObject(vehicleObject, index = 0) {
      const dealerObject = this.getDealerByMobileSellerId(
        vehicleObject.mobileSellerId
      )

      const normalizedPrice = getNormalizedPriceData(vehicleObject.price)

      const vehicleTypeLetter = getVehicleTypeLetter(vehicleObject)

      const crossedOutPrice = getCrossedOutPrice(
        normalizedPrice,
        vehicleTypeLetter,
        resolveSettingByVehicleType(
          this.settings.priceTrend.maxDaysSinceLastPriceChange,
          vehicleTypeLetter
        ),
        resolveSettingByVehicleType(
          this.settings.priceTrend.minPriceDifferenceInPercent,
          vehicleTypeLetter
        )
      )

      const consumerPriceGross = Math.floor(
        normalizedPrice.consumerPriceGross / 100
      )

      const discount =
        crossedOutPrice !== null
          ? Math.abs(consumerPriceGross - Math.floor(crossedOutPrice / 100))
          : 0

      let availability = null

      if (vehicleObject.deliveryDate !== undefined) {
        const availabilityInWeeks = getDeliveryDateInWeeks(
          vehicleObject.deliveryDate
        )

        if (availabilityInWeeks === 0) {
          availability = this.$t('tracking.deliveryDate.now')
        } else if (availabilityInWeeks <= 12) {
          availability = this.$tc(
            'tracking.deliveryDate.weeks',
            availabilityInWeeks,
            { count: availabilityInWeeks }
          )
        } else {
          const month = getMonth(parseISO(vehicleObject.deliveryDate)) + 1
          const year = getYear(parseISO(vehicleObject.deliveryDate))
          availability = this.$t('tracking.deliveryDate.date', {
            month: this.$t('date.month.short.' + month),
            year
          })
        }
      }

      const trackObject = {
        item_id: vehicleObject.internalNumber,
        vehicle_id: vehicleObject.mobileAdId,
        item_name: getVehicleTitle(vehicleObject),
        affiliation: dealerObject.name,
        currency: 'EUR',
        index,
        item_brand: vehicleObject.make,
        item_category: this.$t(
          'tracking.vehicleClass.' + vehicleObject.vehicleClass
        ),
        item_category2: this.$t(
          'vehicleClass.' +
            vehicleObject.vehicleClass +
            '.amUsageType' +
            vehicleObject.amUsageType
        ),
        item_category3: this.$t(
          'vehicleClass.' +
            vehicleObject.vehicleClass +
            '.category' +
            vehicleObject.category
        ),
        item_category4: vehicleObject.modelGroup,
        item_category5: vehicleObject.model,
        item_variant:
          vehicleObject.exteriorColor !== undefined
            ? this.$t('vehicle.exteriorColor' + vehicleObject.exteriorColor)
            : vehicleObject.manufacturerColorName,
        price: consumerPriceGross,
        quantity: 1,
        custom_model_range: vehicleObject.modelRange
      }

      if (discount > 0) {
        trackObject.discount = discount
      }

      if (dealerObject.vehicleMarket?.googlePlaceId) {
        trackObject.location_id = dealerObject.vehicleMarket?.googlePlaceId
      }

      if (availability !== null) {
        trackObject.custom_availability = availability
      }

      return trackObject
    }
  }
}
